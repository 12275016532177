<template>
  <b-card
    no-body
  >
    <executive-add-row-modal1
      :bpu="bpuSelected"
      @add-new-row="addRow"
    ></executive-add-row-modal1>

    <div
      v-if="isLoadingBpu"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >

      <b-button
        class="mb-1"
        variant="primary"
        @click="showAddRowModal"
        id="btnaddrisk"
      >
       <BIconPlus/> Add Risk/Opps
      </b-button>
      <b-button
        v-if="checkRisks"
        class="mb-1 ml-1"
        variant="danger"
        @click="deleteRisks"
        id="btndelrisk"
      >
       <BIconTrash /> Delete Selected Risk/Opps
      </b-button>
      <Transition>
        <b-button
          v-if="showAddMso"
          class="mb-1 ml-1"
          variant="primary"
          @click="addRowMso"
          :disabled="isDisabledSaveData"
          id="btnaddmso"
        >
          <BIconPlus/> Add MSO
        </b-button>
        <b-button
          v-if="!showAddMso"
          class="mb-1 ml-1"
          variant="danger"
          @click="deleteMSO"
          id="btnaddmso"
        >
          <BIconTrash/> Delete MSO
        </b-button>
      </Transition>
      <b-button
        class="mb-1 ml-1"
        variant="primary"
        @click="saveData"
        :disabled="isDisabledSaveData"
        id="btnsave"
      >
       <BIconArrowBarDown/> Save
      </b-button>
      <v-grid
        ref="refGridSopExecutive"
        :source="source" 
        :columns="columns" 
        :columnTypes="pluginRevo"
        theme="material" 
        resize="true"
        autoSizeColumn="true"
        range="true"
        @beforecellfocus="beforeCellFocus"
        @beforeeditstart="beforeEditStart"
        @focusout="commitCell"
        class="grid-container-sop-executive"
      ></v-grid>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody, BFormGroup, BIconPlus, BIconArrowBarDown, BIconTrash
} from 'bootstrap-vue'
import { ref, toRefs, getCurrentInstance, provide, computed, inject } from 'vue'
import VGrid, { VGridVueTemplate } from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import axios from '@axios'
import FormatCellNumber1 from '../FormatCellNumber1.vue'
import gql from 'graphql-tag'
import ExecutiveAddRowModal1 from './ExecutiveAddRowModal1.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard, 
    BButton, 
    BCardBody,
    BFormGroup,

    CustomLoading,
    ExecutiveAddRowModal1,
    VGrid,
    vSelect,
    BIconPlus, BIconArrowBarDown, BIconTrash
  },
  props: {
    bpuSelected: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const clearableSelect = ref(false)
    const source = ref([])
    // console.log('source', source)
    const refGridSopExecutive = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const modifiedData = ref([])
    const selectedCell = ref(null)
    const selectedRow = ref(null)

    const monthArray = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ]

    // provide
    provide("executiveData", source)

    // inject
    const yearSelected = inject("yearSelected")
    const isLoadingBpu = inject("isLoading")

    // props
    const { bpuSelected } = toRefs(props)

    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
      'decimal': new NumberColumnType('0,0.0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    }

    const vm = getCurrentInstance().proxy

    const getStyleGrid = (prop, model, column) => {
      // console.log('source', source)
      let id = source.value[0].id.toString()
      // let checkMSO = source.value[source.value.length - 11].year.toString()
      // let MSOMeassurements = ["% vs LY", "MSO Euros", "% vs S&OP", "% vs FC"]
      let yearId = parseInt(id.slice(0,4))
      let monthId = parseInt(id.slice(4)) - 1
      let validMonth = monthArray.slice(0, monthId)
      let validMonthStyle = monthArray.slice(0)

      if(model.bpu.length == 0 && model.id.length == 0 && model.year.length == 0) {
        return {
          "border-bottom": "0px solid #ebebeb",
        }
      }

      if(model.bpu.length == 0) {
        return {
          "border-bottom": "3px solid #ebebeb",
        }
      }

      let customStyle = {
        "text-align": "right",
        "border-top": "3px solid #ebebeb",
        "border-right": "3px solid #ebebeb",
        "border-bottom": "3px solid #ebebeb",
        "border-left": "3px solid #ebebeb",
      }

      /* fila de porcentaje */
      if(column.prop == "year" & model.year == 1) {
        return {
          "border-right": "3px solid #ebebeb",
          "color": "transparent"
        }
      }

      if(column.prop == "year" & model.year == 2) {
        return {
          "border-right": "3px solid #ebebeb",
          "color": "transparent"
        }
      }

      /* end fila de porcentaje */

      /* year and bpu */
      
      // COLOR DEL MENU DE LA TABLA EN VERTICAL 
      if (column.prop == "year" || column.prop == "bpu") {
        delete customStyle["text-align"]
        delete customStyle["border-top"]
        customStyle["font-weight"] = "bold"
        customStyle["background"] = "#1e347b"
        customStyle["color"] = "white"

        if(column.prop == "year") {
          delete customStyle["border-right"]
        }

        if(column.prop == "bpu") {
          delete customStyle["border-left"]
        }

      }


      /*end year and bpu */

      /* colocar color a los meses cerrados */
      if(validMonthStyle.includes(column.prop)) {
        delete customStyle["border-left"]
        delete customStyle["border-right"]
        delete customStyle["border-top"]
      }
      
      if (yearSelected.value == yearId) {
        if(validMonth.includes(column.prop)) {
          customStyle["background"] = "#D9E1F2"
        }
      }

      if(column.prop == "total_anho") {
        delete customStyle["border-left"]
        delete customStyle["border-top"]
      }

      /* end colocar color a los meses cerrados */

      
      if(column.prop.toLowerCase() === "q1" 
        || column.prop.toLowerCase() === "q2" 
        || column.prop.toLowerCase() === "q3"
        || column.prop.toLowerCase() === "q4"
      ) {
        customStyle["background"] = "#BFBFBF"
        delete customStyle["border-left"]
        delete customStyle["border-right"]
        delete customStyle["border-top"]
        return customStyle
      }

      return customStyle
    }

    columns.value = [
      {
        "name": bpuSelected.value.toUpperCase(),
        columnProperties: ({prop, model, data, column}) => {
          return {
            style: {
              color: 'white',
              "text-align": "center",
              "background": "#1e347b",
              "font-size": "30px",
            }
          };
        },
        "children": [
          {
            "name": "Year",
            "prop": "year",
            "size": 80,
            "autoSize": true,
            "readonly": true,
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
            columnProperties: ({prop, model, data, column}) => {
              return {
                style: {
                  color: 'transparent',
                  "border-bottom": "3px solid #ebebeb",
                }
              };
            },
          },
          {
            "name": "BPU",
            "prop": "bpu",
            "size": 180,
            "autoSize": true,
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
            columnProperties: ({prop, model, data, column}) => {
              return {
                style: {
                  color: 'transparent',
                  "border-bottom": "3px solid #ebebeb",
                  "border-right": "3px solid #ebebeb",
                }
              };
            },
          },
          {
            "name": "Ene",
            "prop": "enero",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Feb",
            "prop": "febrero",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Mar",
            "prop": "marzo",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q1",
            "prop": "q1",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Abr",
            "prop": "abril",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "May",
            "prop": "mayo",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Jun",
            "prop": "junio",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q2",
            "prop": "q2",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Jul",
            "prop": "julio",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Ago",
            "prop": "agosto",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Sep",
            "prop": "septiembre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q3",
            "prop": "q3",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Oct",
            "prop": "octubre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Nov",
            "prop": "noviembre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Dic",
            "prop": "diciembre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q4",
            "prop": "q4",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Total",
            "prop": "total_anho",
            "size": 90,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                },
                style: {
                  "border-right": "3px solid #ebebeb",
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
        ]
      },
    ]

    const clearRowSelection = () => {
      console.log('checkRisks: ', checkRisks)
      revoRowIndex.value = null
      revoColName.value = null
    }

    const clearRevoValues = () => {
      clearRowSelection()
      modifiedData.value = []
    }

    const beforeEditStart = (event) => {
      let blockQuarter = ['q1', 'q2', 'q3', 'q4', 'total_anho']
      let id = source.value[0].id.toString()
      let yearId = parseInt(id.slice(0,4))
      let monthId = parseInt(id.slice(4)) - 1
      let invalidMonth = monthArray.slice(0, monthId)

      selectedCell.value = event.detail

      if(blockQuarter.includes(selectedCell.value.prop.toLowerCase())) {
        event.preventDefault()
        return
      }

      if (
        !(selectedCell.value.model.year.toLowerCase() == "risk"
        || selectedCell.value.model.year.toLowerCase() == "opps"
        || selectedCell.value.model.year.toLowerCase() == "mso")
      ) {
        event.preventDefault()
        return 
      }

      // if (yearSelected.value == yearId && invalidMonth.includes(selectedCell.value.prop)) {
      //   event.preventDefault()
      //   return 
      // }

      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      console.log('focusOut')
      const originalData = await refGridSopExecutive.value.$el.getSource()
      const viewData = await refGridSopExecutive.value.$el.getVisibleSource()
      let rowData = viewData[revoRowIndex.value]
      let oldValue = rowData[revoColName.value]
      let newValue = event.target.value
      if (oldValue != newValue) {
        rowData[revoColName.value] = newValue
        modifiedData.value.push(Object.assign({}, rowData))
        refGridSopExecutive.value.$el.source = [...originalData]
      }
      clearRowSelection()
    }

    const showAddRowModal = () => {
      vm.$bvModal.show(`executive-add-row-modal-${bpuSelected.value}`)
    }

    const addRow = (newRow) => {
      const riskOpssPosition = 5
      let countRiskOpps = 0
      let insertIndex = 0

      for(let data of source.value) {
        if (data.year.toUpperCase().includes('RISK') 
        || data.year.toUpperCase().includes('OPP') ) {
          countRiskOpps++
        }
      }

      insertIndex = riskOpssPosition + countRiskOpps

      source.value.splice(insertIndex, 0, {
        "id": source.value[0].id,
        "year": newRow.type,
        "bpu": newRow.description,
        "enero": 0,
        "febrero": 0,
        "marzo": 0,
        "q1": 0,
        "abril": 0,
        "mayo": 0,
        "junio": 0,
        "julio": 0,
        "q2": 0,
        "agosto": 0,
        "septiembre": 0,
        "q3": 0,
        "octubre": 0,
        "noviembre": 0,
        "diciembre": 0,
        "q4": 0,
        "total_anho": 0,
      },)
      refGridSopExecutive.value.$el.source = [...source.value]
    }

    const addRowMso = () => {
      let insertIndex = -7

      source.value.splice(insertIndex, 0, {
          "id": source.value[0].id,
          "year": "MSO",
          "bpu": "TOTAL",
          "enero": 0,
          "febrero": 0,
          "marzo": 0,
          "q1": 0,
          "abril": 0,
          "mayo": 0,
          "junio": 0,
          "julio": 0,
          "q2": 0,
          "agosto": 0,
          "septiembre": 0,
          "q3": 0,
          "octubre": 0,
          "noviembre": 0,
          "diciembre": 0,
          "q4": 0,
          "total_anho": 0,
      },)

      refGridSopExecutive.value.$el.source = [...source.value]
    }

    const deleteRisks = () => {
        vm.$swal({
          title: `¿Está seguro de que desea eliminar los Risk/Opp: \n ${selectedRow.value.bpu.toString()}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
        }).then( (res) => {
          if (res.isConfirmed == false ) return
          vm.$apollo.mutate({
            mutation: gql`
              mutation deleteCurrentRiskByName($id: Int, $bpu: String, $name: String ) {
                delete_risk_opp_mso(where: { _and: [
                  {id: { _eq: $id }},
                  {bpu: { _eq: $bpu }},
                  {descripcion: {_eq: $name}},
                  {tipo: { _in: ["Risk", "Opps"] }}
                ] }) {
                  affected_rows
                }
              }
            `,
            variables: {
              id: selectedRow.value.id,
              bpu: bpuSelected.value.toString(),
              name: selectedRow.value.bpu.toString()
            }
          })
          .then(response => {
            console.log('Risks/Opps eliminados')
            selectedRow.value == null
          })
          .catch(error => {
            console.error('Error al eliminar riesgos')
          })

        })
    }

    const deleteMSO = (id, bpu) => {
        // console.log('Erasing MSO with', source.value[0].id.toString(), ',', source.value[0].bpu.toString())

        vm.$swal({
          title: `¿Está seguro de que desea eliminar el MSO?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
        }).then( (id, bpu) => {
          vm.$apollo.mutate({
            mutation: gql`
              mutation deleteCurrentRiskByName($id: Int, $bpu: String) {
                delete_risk_opp_mso(
                  where: {_and: [ {id: {_eq: $id}}, {bpu: {_eq: $bpu}}, {tipo: { _eq: "MSO" }} ]}
                ) {
                  affected_rows
                }
              }
            `,
            variables: {
              id: source.value[0].id.toString(),
              bpu: source.value[0].bpu.toString()
            }
          })
          .then(response => {
            console.log('MSO eliminados:', response.data.delete_risk_opp_mso.affected_rows)
          })
          .catch(error => {
            console.error('Error al eliminar riesgos:', error)
          })

        })
    }

    const saveData = () => {
      let dataRiskOpps = []

      for(let data of source.value) {
        if (data.year.toUpperCase().includes('RISK') 
          || data.year.toUpperCase().includes('OPP')
          || data.year.toUpperCase().includes('MSO')) {
          dataRiskOpps.push({
            "id": data.id,
            "tipo": data.year,
            "bpu": bpuSelected.value,
            "description": data.bpu,
            "enero": Number(data.enero),
            "febrero": Number(data.febrero),
            "marzo": Number(data.marzo),
            "abril": Number(data.abril),
            "mayo": Number(data.mayo),
            "junio": Number(data.junio),
            "julio": Number(data.julio),
            "agosto": Number(data.agosto),
            "septiembre": Number(data.septiembre),
            "octubre": Number(data.octubre),
            "noviembre": Number(data.noviembre),
            "diciembre": Number(data.diciembre),
            "year": yearSelected.value
          })
        }
      }

      if(modifiedData.value.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que desea guardar los cambios?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              .post(`${process.env.VUE_APP_BASE_URL}/save_risk_ops`,{
                data: dataRiskOpps
              })
              .then(response => {
                // isLoadingBpu.value = true
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                vm.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
          },
        }).then( async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          } 
        })
      }
    }

    // subscription
    vm.$apollo.addSmartSubscription('getExecutive', {
      query: gql`
        subscription getExecutive($customWhere: json = null) {
          function_get_executive_v2(args: {customWhere: $customWhere}) {
            year
            bpu
            enero
            febrero
            marzo
            q1
            abril
            mayo
            junio
            q2
            julio
            agosto
            septiembre
            q3
            octubre
            noviembre
            diciembre
            q4
            total_anho
            id            
          }
        }
      `,
      variables(){
        return {
          customWhere: {
            "bpu":[bpuSelected.value],
            "year":[yearSelected.value]
          }
        }
      },
      result ({data}) {
        isLoadingBpu.value = false
        console.log('data', data)
        source.value = data.function_get_executive_v2

        // remove _typename
        for(let obj of source.value) {
          if (obj.__typename) delete obj.__typename
        }
      },
    })

    // computed
    const isDisabledSaveData = computed(() => {
      return modifiedData.value.length <= 0
    })

    const checkRisks = computed(() => {
      if (source.value.length === 0) return
      if (selectedRow.value == null ) return
      // console.log('selectedRow', selectedRow)

      // let idSelected = source.value[revoRowIndex.value].id
      // var category = bpuSelected.value
      // console.log('IDSelected: ', source.value[revoRowIndex.value].id )
      // console.log('YearSelected: ', source.value[revoRowIndex.value].year.toUpperCase())
      // console.log('BPUSelected: ', source.value[revoRowIndex.value].bpu)
      // console.log('CategorySelected: ', bpuSelected.value)

      // var checkifRiskOppExists = source.value[5].year.toUpperCase() == "RISK" || source.value[5].year.toUpperCase() == "OPPS"
      // var checkRiskOppSelected = selectedCell.value == null ? false : selectedCell.value.year.toUpperCase() == "RISK" || selectedCell.value.year.toUpperCase() == "OPPS"
      // console.log('checkifRiskOppExists:', checkifRiskOppExists)
      // console.log('checkRiskOppSelected:', checkRiskOppSelected)
      return selectedRow.value.year.toUpperCase() == 'RISK' || selectedRow.value.year.toUpperCase() == 'OPPS'
    })

    const showAddMso = computed(() => {
      if (source.value.length === 0) return
      return source.value[source.value.length - 11].year.toUpperCase() !== "MSO"
    })

    const beforeCellFocus = (event) => {
      if (event.detail == null ) return
      if (source.value.length == null ) return
      selectedRow.value = source.value[event.detail.rowIndex]
      return
    }

    return {
      clearableSelect,
      source,
      columns,
      isLoadingBpu,
      refGridSopExecutive,
      pluginRevo,
      isDisabledSaveData,
      checkRisks,
      showAddMso,
      beforeCellFocus,
      selectedRow,
      beforeEditStart,
      commitCell,
      addRow,
      addRowMso,
      showAddRowModal,
      saveData,
      deleteRisks,
      deleteMSO
    }
  },
}
</script>

<style lang="scss">
.revo-header {
  background: #1e347b;
  font-weight: bold;
  color: white;
  text-align: center;
  border-bottom: 3px solid #ebebeb;
  border-top: 3px solid #ebebeb;
}

.revo-header-quarter {
  background: #BFBFBF;
  font-weight: bold;
  color: black;
  text-align: center;
  border-top: 3px solid #ebebeb;
  border-bottom: 3px solid #ebebeb;
}

.title-month {
  font-size: 35px;
  font-weight: bold;
}

.grid-container-sop-executive {
  width: 100%;
  height: 800px;
  font-family: "Segoe UI" !important;
  font-weight: bold;
  font-size: 15px;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box;
  }

  .group-rgRow{
    margin-bottom: 17px;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }

  .header-rgRow:not(.group) {
    box-shadow: none;
  }

  revogr-header .header-rgRow.group {
    box-shadow: none;
  }

  revogr-data .rgRow  {
    box-shadow: none;
  }

  revogr-header .rgHeaderCell.focused-cell {
    color: black;
  }
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

// #btnaddrisk{
//   background-color: #ff5e00;
//   color: white;
// }
// #btnsave{
//   background-color: green; 
//   color: white;
// }

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
